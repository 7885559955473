* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  --dark-background: #272727;
  --light-background: #FAFAFA;
  --grey-background: rgba(0, 0, 0, .7);
  --grey: rgba(0,0,0,.08);
  --logo-translate: -1rem;
  --cubic: cubic-bezier(0.4, 0.0, 0.2, 1);

  margin: 0;
  font-size: 16px;
  -webkit-clip-path: polygon(2% 1%, 98% 2%, 96% 97%, 0% 100%);
  clip-path: polygon(2% 1%, 98% 2%, 96% 97%, 0% 100%);
  background-color: var(--dark-background);
  transition: 400ms background var(--cubic);
}

:global body.invert {
  --light-background: #272727;
  --dark-background: #FAFAFA;
}

@media (prefers-color-scheme: dark) {
  body {
    --light-background: #272727;
    --dark-background: #FAFAFA;
  }

  :global body.invert {
    --dark-background: #272727;
    --light-background: #FAFAFA;
  }
}

section {
  max-width: 1200px;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto auto 1fr;
}

section::after {
  content: '';
  height: 2rem;
  width: 100%;
  display: block;
}

:global #body {
  padding: 4% 5%;
  background-color: var(--light-background);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transition: 400ms background var(--cubic);
}

:global #transition {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
  background: var(--grey-background);
}

:global #transition svg {
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  height: 200px;
  transform-origin: center;
  transform: rotate(-35deg) skew(-65deg);
  overflow: visible;
  opacity: 0;
}

:global #top rect, :global #top path {
  fill: var(--light-background);
  stroke: var(--grey);
}

:global #bottom rect, :global #bottom path {
  fill: var(--dark-background);
  stroke: var(--grey);
}

app-footer {
  margin: 2rem 0;
  align-self: end;
}

app-header {
  padding-bottom: 3rem;
}

main {
  position: relative;
}

main > * {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  top: 0;
  left: 0;
  max-height: 50vh;
  overflow: hidden;
  visibility: hidden;
}


main :global .active {
  position: relative !important;
  max-height: none !important;
  overflow: unset !important;
  pointer-events: auto !important;
  visibility: visible !important;
  z-index: 1;
  /* opacity: 1 !important; */
}

:global .logo {
  display: none;
  height: 100%;
  transition: 400ms transform var(--cubic);
  transform-origin: center;
  transform: scale(2.2, 2.2) rotateX(-45deg) rotateY(-20deg) rotateZ(-35deg) skewX(-20deg) translateY(var(--logo-translate));
}

:global .invert .logo {
  transform: scale(2.2, 2.2) rotateX(-45deg) rotateY(20deg) rotateZ(-15deg) skewX(-20deg) translateY(var(--logo-translate));
}

:global .logo svg {
  fill: var(--dark-background);
}

@media only screen and (max-width: 1200px) {
  body {
    --logo-translate: -0.5rem;
  }
}

@media only screen and (max-width: 800px) {
  body {
    --logo-translate: 0;
  }
}